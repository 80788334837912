var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('router-link',{attrs:{"to":{
      name: 'authorViewBlog',
      params: {
        anchor: _vm.blog.anchor,
        authAuthor: _vm.blog.userName,
        review: _vm.review
      }
    }}},[_c('v-card',{attrs:{"max-width":"1200"}},[_c('v-card-title',[_c('div',{staticClass:"display-2"},[_vm._v(_vm._s(_vm.blog.title))])]),_c('v-card-subtitle',[_c('div',{staticClass:"body-2 accent--text "},[_c('p',[(_vm.blog.pubDate)?_c('span',[_vm._v("Published on "+_vm._s(_vm.customFormatter(_vm.blog.pubDate)))]):(_vm.blog.reqRv)?_c('span',[_c('b',{staticClass:"primary--text"},[_vm._v("Under review for publication")])]):_c('span',[_c('b',{staticClass:"primary--text"},[_vm._v("In draft")])])])])]),_c('v-card-text',[_c('markdown-it-vue',{class:_vm.md_text,attrs:{"content":_vm.blog.summary}})],1),_c('v-card-actions',[_c('v-alert',{staticClass:"text-center headline",attrs:{"color":"grey","width":"100%"}},[_vm._v(" Preview and Manage ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }